import { EventObject, Typestate } from 'xstate';
import { AppMode } from '../../stores/order.store';
import { Order } from '../../gql/graphql';
import { PaymentSelectionErrors } from '../../pages/paymentSelectionError/PaymentSelectionError';

// Context Types
export type ExistingApplicationMachineContext = {
  order: Order | null;
  appMode: AppMode | null;
  skipApproval: boolean;
  canGoBackViaHeader: boolean;
  skipToPage?: string | null;
  ecommApprovalOnly?: boolean;
  bankAcctLast4: string;
  bankName: string;
  plaidFF: boolean;
  plaidAB: boolean;
  plaidLoading: boolean;
  paymentSelectionError?: PaymentSelectionErrors;
  linkToken?: string;
  orderId: string;
  plaidToken: {
    linkToken: string;
    publicToken: string;
    expDate: string;
  };
  isTokenizedAcct: boolean;
};

// State Types
export enum ExistingApplicationStates {
  LoanApplication = 'LoanApplication',
  PaymentSchedule = 'PaymentSchedule',
  AutoPay = 'AutoPayEnrollment',
  FinalApproval = 'FinalApproval',
  CartValidation = 'CartValidation',
  ApplicationExists = 'ApplicationExists',
  LeaseApplication = 'LeaseApplication',
  ContractSummary = 'ContractSummary',
  LeaseAgreement = 'LeaseAgreement',
  DebitCardEntry = 'DebitCardEntry',
  DebitCardError = 'DebitCardError',
  DebitCardHardDecline = 'DebitCardHardDecline',
  DebitCardSoftDecline = 'DebitCardSoftDecline',
  DebitCardFatalError = 'DebitCardFatalError',
  BankAccountError = 'BankAccountError',
  BankAccountHardDecline = 'BankAccountHardDecline',
  Loading = 'Loading',
  CreditPullAuth = 'CreditPullAuth',
  VirtualCardApplication = 'VirtualCardApplication',
  WelcomeBack = 'WelcomeBack',
  PayFrequency = 'PayFrequency',
  BankDetails = 'BankDetails',
  Approval = 'Approval',
  Prequalification = 'Prequalification',
  ItemEntry = 'ItemEntry',
  CartSummary = 'CartSummary',
  CartTotal = 'CartTotal',
  VirtualCardPage = 'VirtualCardPage',
  AuthorizedUserTerms = 'AuthorizedUserTerms',
  LeaseDecline = 'LeaseDecline',
  LoanDecline = 'LoanDecline',
  Signed = 'Signed',
  CheckLeaseStatus = 'CheckLeaseStatus',
  ApprovalConfirmation = 'ApprovalConfirmation',
  PaymentSelection = 'PaymentSelection',
  PaymentSelectionError = 'PaymentSelectionError',
  PlaidModal = 'PlaidModal',
  PaymentReview = 'PaymentReview',
  FetchLinkToken = 'FetchLinkToken',
  UpdateBankAccount = 'UpdateBankAccount',
}

export interface ExistingApplicationMachineStatesSchema {
  [ExistingApplicationStates.LoanApplication]: {};
  [ExistingApplicationStates.FinalApproval]: {};
  [ExistingApplicationStates.AutoPay]: {};
  [ExistingApplicationStates.ApplicationExists]: {};
  [ExistingApplicationStates.LeaseApplication]: {};
  [ExistingApplicationStates.ContractSummary]: {};
  [ExistingApplicationStates.Loading]: {};
  [ExistingApplicationStates.LeaseAgreement]: {};
  [ExistingApplicationStates.DebitCardEntry]: {};
  [ExistingApplicationStates.DebitCardError]: {};
  [ExistingApplicationStates.DebitCardHardDecline]: {};
  [ExistingApplicationStates.DebitCardSoftDecline]: {};
  [ExistingApplicationStates.DebitCardFatalError]: {};
  [ExistingApplicationStates.BankAccountError]: {};
  [ExistingApplicationStates.BankAccountHardDecline]: {};
  [ExistingApplicationStates.Loading]: {};
  [ExistingApplicationStates.CreditPullAuth]: {};
  [ExistingApplicationStates.VirtualCardApplication]: {};
  [ExistingApplicationStates.WelcomeBack]: {};
  [ExistingApplicationStates.PayFrequency]: {};
  [ExistingApplicationStates.BankDetails]: {};
  [ExistingApplicationStates.Prequalification]: {};
  [ExistingApplicationStates.Approval]: {};
  [ExistingApplicationStates.ItemEntry]: {};
  [ExistingApplicationStates.CartSummary]: {};
  [ExistingApplicationStates.CartTotal]: {};
  [ExistingApplicationStates.VirtualCardPage]: {};
  [ExistingApplicationStates.AuthorizedUserTerms]: {};
  [ExistingApplicationStates.CheckLeaseStatus]: {};
  [ExistingApplicationStates.ApprovalConfirmation]: {};
  [ExistingApplicationStates.PaymentSelection]: {};
  [ExistingApplicationStates.PaymentSelectionError]: {};
  [ExistingApplicationStates.PaymentReview]: {};
  [ExistingApplicationStates.PlaidModal]: {};
  [ExistingApplicationStates.FetchLinkToken]: {};
  [ExistingApplicationStates.UpdateBankAccount]: {};
}

export interface ExistingApplicationMachineStates extends Typestate<ExistingApplicationMachineContext> {
  type: ExistingApplicationMachineStatesSchema;
}

// Event Types
export enum ExistingApplicationEvents {
  NEXT = 'NEXT',
  BACK = 'BACK',
  ERROR = 'ERROR',
  CREDIT_PULL = 'CreditPull',
  OTB = 'OTB',
  EDIT_DEBIT_INFO = 'EditDebitInfo',
  SELECT_PLAID = 'SelectPlaid',
  SELECT_MANUAL = 'SelectManual',
  FETCH_LINK_TOKEN = 'FetchLinkToken',
  PLAID_MODAL_LOADING = 'PlaidModalLoading',
  UPDATE_BANK_ACCOUNT = 'UpdateBankAccount',
}

type ExistingApplicationEventsTypeSchema =
  | ExistingApplicationEvents.NEXT
  | ExistingApplicationEvents.BACK
  | ExistingApplicationEvents.ERROR
  | ExistingApplicationEvents.CREDIT_PULL
  | ExistingApplicationEvents.EDIT_DEBIT_INFO
  | ExistingApplicationEvents.OTB
  | ExistingApplicationEvents.SELECT_PLAID
  | ExistingApplicationEvents.SELECT_MANUAL
  | ExistingApplicationEvents.FETCH_LINK_TOKEN
  | ExistingApplicationEvents.PLAID_MODAL_LOADING
  | ExistingApplicationEvents.UPDATE_BANK_ACCOUNT;

export interface ExistingApplicationMachineEvents extends EventObject {
  type: ExistingApplicationEventsTypeSchema;
  data?: any;
}

// Guard Types
export enum ExistingApplicationGuardsType {
  APP_MODE_LEASE = 'AppModeLease',
  APP_MODE_LOAN = 'AppModeLoan',
}
type ExistingApplicationGuardsTypeSchema = ExistingApplicationGuardsType.APP_MODE_LEASE | ExistingApplicationGuardsType.APP_MODE_LOAN;

export interface ExistingApplicationMachineGuards extends EventObject {
  type: ExistingApplicationGuardsTypeSchema;
}
