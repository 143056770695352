import { actions, assign, createMachine } from 'xstate';
import { NavigationSections } from '../constants';
import { transferToRapid } from '../helpers/cookies/unified.helper';
import {
  LongFormEvents,
  LongFormMachineContext,
  LongFormMachineEvents,
  LongFormMachineGuards,
  LongFormMachineStates,
  LongFormStates,
} from './types/longFormMachine.types';
import { PaymentSelectionErrors } from '../pages/paymentSelectionError/PaymentSelectionError';
import { BankConnectionDetails, BankConnectionTypes, UpdateBankAccountErrors, UpdateBankAccountInput, AppSteps, BankConnectionError } from '../gql/graphql';
import applicationFormService from '../services/applicationForm.service';
import { error } from 'xstate/lib/actions';
import bankConnectionService from '../services/bankConnectionService';

const determinePlaidFF = (context: LongFormMachineContext) => {
  // // Gitlab Main shutoff point
  // const levelOne = context.plaidFF;
  // // Dealer level enablement
  // const levelTwo = context.order?.dealer.supportsExternalBankConnector;
  // let levelThree = true;
  // // Plaid A/B testing dependent on customer id
  // // Can be switched on/off at gitlab feature flag level
  // if (context.plaidAB) {
  //   // Feature flag enabled for customers with EVEN ids
  //   levelThree = context.order?.application?.customer?.id! % 2 === 0;
  // }
  // return levelOne && levelTwo && levelThree;
  return false;
};

const updateBankAccount = async (appId: number, plaidPublicToken: string) => {
  const connectDetails: BankConnectionDetails = {
    bankConnectionType: BankConnectionTypes.Plaid,
    bankConnectionToken: plaidPublicToken,
  };
  const updateBankAccountPayload: UpdateBankAccountInput = {
    applicationId: appId,
    bankConnectionDetails: connectDetails,
  };
  try {
    const { data, errors } = (await applicationFormService.updateBankAccount(updateBankAccountPayload)) as any;
    if (errors) {
      throw error;
    }

    // EMILY REMOVE THIS ONCE UI DEPLOYED IN DEV OR SANDBOX
    data.updateBankAccount.updateBankAccountErrors = [];
    return data.updateBankAccount;
  } catch {
    throw error;
  }
};

const fetchLinkToken = async (orderId: string) => {
  const { data, errors } = (await bankConnectionService.generateBankConnectorToken(orderId)) as any;
  if (errors) {
    throw error;
  }
  // PLAID FIX -- change this once AES MR is merged (run codegen to regenerate)
  return data.generateBankConnectorToken;
};

export default createMachine<LongFormMachineContext, LongFormMachineEvents, LongFormMachineStates>(
  {
    id: 'Draft',
    initial: LongFormStates.Entry,
    context: {
      order: null,
      eligibilityDetails: null,
      transitionedFrom: null,
      highestSupportedApplicationMilestone: null,
      hardDeclineError: undefined,
      softDeclineError: undefined,
      skipToPage: null,
      isReadOnly: null,
      plaidFF: false,
      plaidLoading: true,
      orderId: '',
      plaidToken: { publicToken: '', expDate: '', linkToken: '' },
    },
    states: {
      // ephemeral state, used exclusively for immediate switching
      [LongFormStates.Entry]: {
        always: [
          {
            target: LongFormStates.ReviewAndSubmit,
            cond: 'AppStepViewApplicationDetails',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
              isReadOnly: () => true,
            }),
          },
          {
            target: LongFormStates.PersonalInformation,
            cond: 'NoApplication',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.PersonalInformation,
            cond: 'NoAppStep',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.PersonalInformation,
            cond: 'AppStepPersonalInformation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ContactInformation,
            cond: 'AppStepContactData',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.IncomeSource,
            cond: 'AppStepIncomeSource',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.PaymentSelection,
            cond: 'AppStepBankingInformationFF',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.BankingInformation,
            cond: 'AppStepBankingInformation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.DebitCardInformation,
            cond: 'AppStepDebitCardInformation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.TransferToRapid,
            cond: 'AppStepReviewAndSubmitM2',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ReviewAndSubmit,
            cond: 'AppStepReviewAndSubmitM3',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ReviewAndSubmit,
            cond: 'AppStepAmendAndResubmit',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.ApprovalConfirmation,
            cond: 'ApprovalConfirmation',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.Declined,
            cond: 'Declined',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
          {
            target: LongFormStates.PreApproval,
            cond: 'PreApproval',
            actions: assign({
              transitionedFrom: () => LongFormStates.Entry,
            }),
          },
        ],
      },
      [LongFormStates.PersonalInformation]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.PersonalInformation,
              }),
            },
            {
              target: LongFormStates.ContactInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.PersonalInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.PersonalInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
      [LongFormStates.ContactInformation]: {
        exit: 'log',
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
            {
              target: LongFormStates.Ineligible,
              cond: 'IsIneligible',
              actions: assign({
                eligibilityDetails: (_context, event) => event.data?.eligibilityDetails,
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
            {
              target: LongFormStates.BillingAddress,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: [LongFormStates.PersonalInformation],
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.BillingAddress]: {
        // comes after basic info continue, but doesnt have an associated appStep
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.IncomeSource,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                order: (_context, event) => event?.data?.order,
                transitionedFrom: () => LongFormStates.BillingAddress,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.BillingAddress,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: [LongFormStates.ContactInformation],
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.BillingAddress,
              }),
            },
          ],
          [LongFormEvents.VIEW_CUSTOMER_ID_PROGRAM]: {
            target: LongFormStates.CustomerIdProgram,
          },
          [LongFormEvents.VIEW_TERMS_AND_CONDITIONS]: {
            target: LongFormStates.TermsAndConditions,
          },
          [LongFormEvents.VIEW_PREQUALIFICATION_NOTICE]: {
            target: LongFormStates.PrequalificationNotice,
          },
          [LongFormEvents.VIEW_AUTHORIZED_USER_TERMS]: {
            target: LongFormStates.AuthorizedUserTerms,
          },
        },
        meta: {
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.IncomeSource]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.IncomeSource,
              }),
            },
            // Feature flag takes precedence over other checks.
            { target: LongFormStates.PaymentSelection, cond: 'PaymentSelectFF' },
            {
              target: LongFormStates.BankingInformation, // is bav turned off for any long form stores?
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.IncomeSource,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.BillingAddress,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.IncomeSource,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.ContactInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.PaymentSelection]: {
        initial: 'PaymentSelectionInit',
        states: {
          PaymentSelectionInit: {
            on: {
              [LongFormEvents.SELECT_PLAID]: [
                {
                  target: LongFormStates.FetchLinkToken,
                },
              ],
              [LongFormEvents.SELECT_MANUAL]: {
                target: '#Draft.bankingInformation',
              },
            },
          },
          [LongFormEvents.FETCH_LINK_TOKEN]: {
            // @ts-ignore
            invoke: {
              id: 'FetchLinkToken',
              src: (context: LongFormMachineContext, event: LongFormMachineEvents) => fetchLinkToken(context.orderId),
              onDone: [
                {
                  cond: 'LinkTokenFailure',
                  target: '#Draft.PaymentSelectionError',
                  // @ts-ignore
                  actions: assign({ error: (context, event) => PaymentSelectionErrors.ineligibleError }),
                },
                {
                  target: '#Draft.PlaidModal',
                  // @ts-ignore
                  actions: assign({
                    plaidToken: (_context, event: any) => {
                      return {
                        linkToken: event?.data?.linkToken,
                        expDate: event?.data?.linkTokenExpiration,
                        publicToken: '',
                      };
                    },
                  }),
                },
              ],
              onError: {
                target: '#Draft.PaymentSelectionError',
                // @ts-ignore
                actions: assign({ error: (context, event) => PaymentSelectionErrors.ineligibleError }),
              },
            },
          },
        },
      },
      [LongFormStates.PlaidModal]: {
        initial: 'PlaidModalInit',
        states: {
          PlaidModalInit: {
            on: {
              [LongFormEvents.BACK]: {
                target: '#Draft.PaymentSelection',
              },
              [LongFormEvents.NEXT]: [
                {
                  target: 'PlaidUpdateBankAccount',
                  actions: [
                    actions.assign({
                      // @ts-ignore
                      bankAcctLast4: (_context, event) => event?.data?.bankAcctLast4,
                      // @ts-ignore
                      bankName: (context, event) => event?.data?.bankName,
                      plaidToken: (context: LongFormMachineContext, event: LongFormMachineEvents) => {
                        return { ...context.plaidToken, publicToken: event?.data?.publicToken || '' };
                      },
                    }),
                  ],
                },
              ],
            },
          },
          PlaidUpdateBankAccount: {
            // @ts-ignore
            invoke: {
              id: 'updateBankAccount',
              src: (context: LongFormMachineContext, event: LongFormMachineEvents) =>
                // We know application will have id at this point in the flow
                // We also know that bank account is only currently present for leases
                updateBankAccount(context.order?.application?.lease!.id!, context.plaidToken.publicToken),
              onDone: [
                {
                  cond: 'TokenizedAcct',
                  target: '#Draft.PaymentSelectionError',
                  // @ts-ignore
                  actions: assign({
                    paymentSelectionError: (context, event) => PaymentSelectionErrors.ineligibleError,
                  }),
                },
                {
                  cond: 'PaymentSelectionError',
                  target: '#Draft.PaymentSelectionError',
                  // @ts-ignore
                  actions: assign({
                    paymentSelectionError: (context, event) => PaymentSelectionErrors.ineligibleError,
                  }),
                },
                {
                  cond: 'PlaidSuccess',
                  target: '#Draft.PaymentReview',
                },
              ],

              onError: {
                target: '#Draft.PaymentSelectionError',
                // @ts-ignore
                actions: assign({ error: (context, event) => event.error }),
              },
            },
          },
        },
      },
      [LongFormStates.PaymentSelectionError]: {
        on: {
          [LongFormEvents.SELECT_PLAID]: [{ target: LongFormStates.PaymentSelection }],
          [LongFormEvents.SELECT_MANUAL]: {
            target: LongFormStates.BankingInformation,
          },
        },
      },
      [LongFormStates.PaymentReview]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.DebitCardInformation,
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.PaymentReview,
              }),
            },
          ],
        },
      },
      [LongFormStates.BankingInformation]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.DebitCardInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.IncomeSource,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.BankingInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.DebitCardInformation]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.TransferToRapid,
              cond: (context, _event) =>
                (!context.highestSupportedApplicationMilestone || context.highestSupportedApplicationMilestone < 3) &&
                context.transitionedFrom !== LongFormStates.ReviewAndSubmit,
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: (context, _event) =>
                !!context.highestSupportedApplicationMilestone &&
                context.highestSupportedApplicationMilestone >= 3 &&
                context.transitionedFrom !== LongFormStates.ReviewAndSubmit,
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
          ],
          [LongFormEvents.BACK]: [
            {
              target: LongFormStates.BankingInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
          ],
          [LongFormEvents.CancelButtonClicked]: [
            {
              target: [LongFormStates.ReviewAndSubmit],
              actions: assign({
                transitionedFrom: () => LongFormStates.DebitCardInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [LongFormStates.ReviewAndSubmit]: {
        on: {
          [LongFormEvents.NEXT]: [
            {
              target: LongFormStates.ContactInformation,
              cond: (_context, event) => {
                return event?.data?.target === LongFormStates.ContactInformation;
              },
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.PersonalInformation,
              cond: (_context, event) => {
                return event?.data?.target === LongFormStates.PersonalInformation;
              },
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.BillingAddress,
              cond: (_context, event) => event?.data?.target === LongFormStates.BillingAddress,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.PaymentSelection,
              cond: 'EditPaymentSelection',
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.IncomeSource,
              cond: (_context, event) => event?.data?.target === LongFormStates.IncomeSource,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.BankingInformation,
              cond: (_context, event) => event?.data?.target === LongFormStates.BankingInformation,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.DebitCardInformation,
              cond: (_context, event) => event?.data?.target === LongFormStates.DebitCardInformation,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.PreApproval,
              cond: (_context, event) => event?.data?.target === LongFormStates.PreApproval,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },
            {
              target: LongFormStates.InternalError,
              cond: (_context, event) => event?.data?.target === LongFormStates.InternalError,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
              }),
            },

            {
              target: LongFormStates.Declined,
              actions: assign({
                transitionedFrom: () => LongFormStates.ReviewAndSubmit,
                hardDeclineError: (_context, event) => event?.data?.hardDeclineError,
              }),
            },
          ],
          [LongFormEvents.BACK]: {
            target: LongFormStates.DebitCardInformation,
            actions: assign({
              // TODO: [M3] Handle how far you can go 'back' in the flow from ReviewAndSubmit. This will create a loop between debit card and review pages
              transitionedFrom: () => LongFormStates.ReviewAndSubmit,
            }),
          },
        },
        // meta: {
        //   navigationSection: NavigationSections.REVIEW_AND_PAY,
        //   canGoBackViaHeader: true,
        // },
      },
      [LongFormStates.TransferToRapid]: {
        entry: 'transferToRapid',
      },

      // info pages
      [LongFormStates.CustomerIdProgram]: {
        on: {
          [LongFormEvents.NEXT]: { target: LongFormStates.BillingAddress },
        },
      },
      [LongFormStates.TermsAndConditions]: {
        on: {
          [LongFormEvents.NEXT]: {
            target: LongFormStates.BillingAddress,
          },
        },
      },
      [LongFormStates.PrequalificationNotice]: {
        on: {
          [LongFormEvents.NEXT]: {
            target: LongFormStates.BillingAddress,
          },
        },
      },
      [LongFormStates.AuthorizedUserTerms]: {
        on: {
          [LongFormEvents.BACK]: {
            target: LongFormStates.BillingAddress,
          },
        },
      },
      [LongFormStates.Declined]: {
        // TODO: [M3] What events can happen from the decline screen that need to be handled here?
      },
      [LongFormStates.PreApproval]: {
        on: {
          [LongFormEvents.APPROVED]: {
            target: LongFormStates.ApprovalConfirmation,
            actions: assign({
              transitionedFrom: () => LongFormStates.PreApproval,
            }),
          },
          [LongFormEvents.DECLINED_OR_ERROR]: [
            {
              target: LongFormStates.Declined,
              cond: (_context, event) => event?.data?.target === LongFormStates.Declined,
              actions: assign({
                transitionedFrom: () => LongFormStates.PreApproval,
                hardDeclineError: (_context, event) => event?.data?.hardDeclineError,
              }),
            },
            {
              target: LongFormStates.ReviewAndSubmit,
              cond: (_context, event) => event?.data?.target === LongFormStates.ReviewAndSubmit,
              actions: assign({
                transitionedFrom: () => LongFormStates.PreApproval,
                softDeclineError: (_context, event) => event?.data?.softDeclineError,
              }),
            },
          ],
        },
        meta: {
          canGoBackViaHeader: false,
        },
      },
      [LongFormStates.Approval]: {
        on: {
          [LongFormEvents.NEXT]: {
            target: LongFormStates.ApprovalConfirmation,
            actions: assign({
              transitionedFrom: () => LongFormStates.Approval,
            }),
          },
        },
        meta: {
          canGoBackViaHeader: false,
        },
      },
      [LongFormStates.ApprovalConfirmation]: {},
      [LongFormStates.InternalError]: {},
      [LongFormStates.Ineligible]: {},
    },
    schema: {
      context: {} as LongFormMachineContext,
      events: {} as LongFormMachineEvents,
      guards: {} as LongFormMachineGuards,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      transferToRapid: () => {
        transferToRapid();
      },
      // strictly for debugging, do not remove and make sure you clean up references in MRs
      log: (context, event) => {
        console.log('Draft Application');
        console.log('context', context);
        console.log('event', event);
      },
    },
    guards: {
      NoApplication: (context) => {
        return !context.order?.application?.lease?.id;
      },
      NoAppStep: (context) => {
        return !context.order?.application?.lease?.appStep;
      },
      AppStepPersonalInformation: (context) => {
        return context.order?.application?.lease?.appStep === AppSteps.PersonalInformation;
      },
      AppStepContactData: (context) => {
        return context.order?.application?.lease?.appStep === AppSteps.ContactData;
      },
      AppStepIncomeSource: (context) => {
        return context.order?.application?.lease?.appStep === AppSteps.IncomeSource;
      },
      AppStepBankingInformation: (context) => {
        return context.order?.application?.lease?.appStep === AppSteps.BankingInformation;
      },
      // REMOVE WHEN FF IS REMOVED
      AppStepBankingInformationFF: (context) => {
        return context.order?.application?.lease?.appStep === 'bankingInformation' && determinePlaidFF(context);
      },
      AppStepDebitCardInformation: (context) => {
        return context.order?.application?.lease?.appStep === AppSteps.DebitCardInformation;
      },
      AppStepReviewAndSubmitM2: (context) => {
        return (
          (!context.highestSupportedApplicationMilestone || context.highestSupportedApplicationMilestone < 3) &&
          context.order?.application?.lease?.appStep === AppSteps.ReviewAndSubmit &&
          context.order?.application?.lease.status === 'draft'
        );
      },
      AppStepReviewAndSubmitM3: (context) => {
        return (
          !!context.highestSupportedApplicationMilestone &&
          context.highestSupportedApplicationMilestone >= 3 &&
          context.order?.application?.lease?.appStep === AppSteps.ReviewAndSubmit &&
          context.order?.application?.lease.status === 'draft'
        );
      },
      AppStepAmendAndResubmit: (context) => {
        return context.order?.application?.lease?.appStep === AppSteps.AmendAndResubmit;
      },
      AppStepViewApplicationDetails: (context) => {
        return context.order?.application?.lease?.status !== 'draft' && context.order?.application?.lease?.status !== 'errorsFound' && !!context.isReadOnly;
      },
      ApprovalConfirmation: (context) => {
        return context.skipToPage === 'ApprovalConfirmation';
      },
      Declined: (context) => {
        return context.skipToPage === 'Declined';
      },
      PreApproval: (context) => {
        return context.order?.application?.lease?.status === 'preApproved';
      },
      TransitionedFromReviewAndSubmit: (context) => {
        return context.transitionedFrom === LongFormStates.ReviewAndSubmit;
      },
      TransitionedFromAppSteps: (context) => {
        return context.transitionedFrom !== LongFormStates.ReviewAndSubmit;
      },
      IsIneligible: (_context, event) => {
        return event.data?.eligibilityDetails?.isEligible === false || event.data?.eligibilityDetails?.otbCode === 4;
      },
      // Payment Selection/ Plaid specific guards
      PaymentSelectFF: (context, event) => {
        return context.transitionedFrom !== LongFormStates.ReviewAndSubmit && determinePlaidFF(context);
      },
      TokenizedAcct: (_context, event) => {
        if (
          event?.data?.updateBankAccountErrors &&
          event?.data?.updateBankAccountErrors.length > 0 &&
          event?.data?.updateBankAccountErrors.some((err: UpdateBankAccountErrors) => err.__typename === 'BankConnectionError')
        ) {
          const bankConnectionErr = event?.data?.updateBankAccountErrors.find(
            (err: UpdateBankAccountErrors) => err.__typename === 'BankConnectionError',
          ) as BankConnectionError;
          return bankConnectionErr.extensions?.errCode === 'tokenized';
        }
        return false;
      },
      NeoBankError: (_context, event) => {
        return (
          !!event?.data?.updateBankAccountErrors &&
          event?.data?.updateBankAccountErrors.length > 0 &&
          event?.data?.updateBankAccountErrors.some((err: UpdateBankAccountErrors) => err.__typename !== 'SoftDecline')
        );
      },
      PaymentSelectionError: (_context, event) => {
        // General error coming back, etc...
        return !!event?.data?.updateBankAccountErrors && event?.data?.updateBankAccountErrors.length > 0;
      },
      PlaidSuccess: (_context, event) => {
        return !!event?.data?.updateBankAccountErrors && event?.data?.updateBankAccountErrors.length === 0;
      },
      LinkTokenFailure: (_context, event) => {
        return !!event?.data?.generateBankConnectorTokenErrors && event?.data?.generateBankConnectorTokenErrors.length > 0;
      },
      LinkTokenValid: (context, event) => {
        if (context.plaidToken.linkToken !== '' && context.plaidToken.expDate !== '') {
          const nowDate = new Date();
          const tokenExp = new Date(context.plaidToken.expDate);
          return tokenExp > nowDate;
        }
        return false;
      },
      // REMOVE WHEN FF IS REMOVED
      EditPaymentSelection: (context, event) => {
        return determinePlaidFF(context) && event?.data?.target === LongFormStates.IncomeSource;
      },
    },
  },
);
