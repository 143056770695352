import { Button as CLButton } from '@koalafi/component-library';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Button from '../../components/button/Button';
import { FormNavButtonWrapper } from '../../components/formElements/FormElements';
import FormWrapper from '../../components/formWrapper/FormWrapper';
import TextField from '../../components/inputs/TextField';
import { formatToYYYYMMDD, parseDateToSeparateFields, validateDate } from '../../helpers/date/date.helper';
import { useStoreActions, useStoreState } from '../../hooks/easyPeasyHooks';
import { useIdentify, usePage, useTrack } from '../../hooks/segment';
import { CustomerLookupEvents } from '../../state-machine/types/customerLookupMachine.types';
import { LongFormEvents } from '../../state-machine/types/longFormMachine.types';
import { BasicInfoContinuedModel, CustomerFlowType } from '../../stores/applicationForm.store';
import { UpdateBasicInfoContinuedInput } from '../../gql/graphql';

interface BasicInfoContinuedFormModel {
  dob: string;
  taxpayerIdentificationNumber: string;
  monthlyIncome: string;
}

type BasicInfoContinuedProps = {
  messages: {
    submitButton: string;
  };
  showCancelButton?: boolean;
  onNext: (arg: { type: string; data?: any }) => void;
};

const BasicInfoContinued: React.FC<BasicInfoContinuedProps> = ({ onNext, messages, showCancelButton }) => {
  const {
    setBasicInfoContinued,
    customerSearchByCellPhone,
    setApplyErrors,
    setErrorGeneral,
    updateBasicInfoContinued,
    setWasBasicInfoContinuedUpdated,
    setEligibilityUpdated,
  } = useStoreActions((actions) => actions.applicationForm);
  const {
    eligibilityDetails,
    wasEligibilityUpdated,
    basicInfo,
    basicInfoContinued,
    customers,
    cellPhone,
    customerFlowType,
    wasBasicInfoContinuedUpdated,
    isCurrentApplyTypeOTB,
  } = useStoreState((state) => state.applicationForm);
  const identify = useIdentify();
  const track = useTrack();
  const page = usePage();
  const { orderId, order } = useStoreState((state) => state.order);

  const [isLoading, setIsLoading] = useState(false);
  const [isDataSaved, setDataSaved] = useState(false);
  const [wasEligibilitySkipped, setWasEligibilitySkipped] = useState(false);

  // checks if the number after the dash in the appNum is 2 or greater. NaN/undefined > 1 evaluates to false
  const appExists = !!order?.application?.lease?.id;
  const isFirstApp = parseInt(order?.application?.lease?.displayId?.split('-')?.[1]!) === 1;
  const isNewCustomer = !customers?.length;
  const shouldSeeAllFields = appExists ? isFirstApp : isNewCustomer;

  // birthDate is one field in our store. This logic will deconstruct date
  // into appropriate text inputs
  const { day, month, year } = parseDateToSeparateFields(basicInfoContinued?.birthDate);
  const formattedFieldData: BasicInfoContinuedFormModel = {
    monthlyIncome: basicInfoContinued?.monthlyIncome || '',
    taxpayerIdentificationNumber: basicInfoContinued?.taxpayerIdentificationNumber || '',
    dob: basicInfoContinued?.birthDate ? `${month}/${day}/${year}` : '',
  };

  useEffect(() => {
    page('Basic Info Continued');
    setWasBasicInfoContinuedUpdated(false);
    setEligibilityUpdated(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customers && customers[0]?.id) {
      identify({
        customerId: customers[0].id,
        emailAddress: customers[0].emailAddress,
        cellPhone: customers[0].cellPhone,
        firstName: customers[0].firstName,
        lastName: customers[0].lastName,
      });
    } // otherwise, continue to use the anonymous identify
  }, [customers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDataSaved && (wasEligibilityUpdated || wasEligibilitySkipped) && wasBasicInfoContinuedUpdated) {
      if (wasEligibilityUpdated) {
        if (customerFlowType === CustomerFlowType.NewCustomer) {
          track('SSN DOB ROE response', { response: 'new customer' });
        } else if (eligibilityDetails?.isEligible === false || eligibilityDetails?.otbCode === 4) {
          track('SSN DOB ROE response', { response: 'returning - ineligible' });
        } else if (isCurrentApplyTypeOTB) {
          track('SSN DOB ROE response', { response: 'returning - OTB eligible' });
        } else {
          track('SSN DOB ROE response', { response: 'returning - new app' });
        }
      }
      onNext({ type: CustomerLookupEvents.NEXT, data: { eligibilityDetails } });
    }
  }, [
    wasEligibilityUpdated,
    wasEligibilitySkipped,
    eligibilityDetails,
    isDataSaved,
    wasBasicInfoContinuedUpdated,
    customerFlowType,
    isCurrentApplyTypeOTB,
    onNext,
    track,
  ]);

  const IncomeTooltipContent = (
    <p>
      You don't have to include alimony, child support or separate maintenance income if you don't choose to have it considered in our decision to offer you
      financing.
    </p>
  );

  // For birthDate inputs, we have yup field validation as well as handling any
  // date logic upon submission.
  let validateBirthdateError: string | undefined;
  const validateBirthdate = (values: any) => {
    const errors: any = {};
    validateBirthdateError = undefined;

    const [dobMonth, dobDay, dobYear] = values.dob.split('/');

    // if year is not 4 digits long, will let yup validation handle
    if (!dobYear || dobYear?.length !== 4) return;
    validateBirthdateError = validateDate({
      year: dobYear,
      month: dobMonth,
      day: dobDay,
      maxYearsAgo: 125,
      minYearsAgo: 18,
      errorMsg: 'You must be at least 18 years of age to apply',
    });
    if (validateBirthdateError) {
      // adding this highlights the text inputs with red to signal issue
      errors.dob = validateBirthdateError;
    }
    return errors;
  };

  const handleSubmit = async (e: BasicInfoContinuedFormModel) => {
    setIsLoading(true);
    if (validateBirthdateError) {
      setIsLoading(false);
      return;
    }

    const [dobMonth, dobDay, dobYear] = e.dob.split('/');
    // this track needs to be below validateBirthdayError
    track('ID & Income Entered', { monthlyIncome: e.monthlyIncome, birthYear: dobYear });
    // This combines separate birthDate inputs into one field for store
    const modifiedFormData: BasicInfoContinuedModel = {
      birthDate: formatToYYYYMMDD({ day: dobDay, month: dobMonth, year: dobYear }),
      monthlyIncome: e.monthlyIncome.replace('$', '').replace(',', ''),
      taxpayerIdentificationNumber: e.taxpayerIdentificationNumber,
    };

    // Reset errors
    setApplyErrors([]);
    setEligibilityUpdated(false);
    setErrorGeneral(null);

    setBasicInfoContinued({ ...basicInfoContinued, ...modifiedFormData });

    if (!!order?.application?.lease?.id) {
      const updateBasicInfoContinuedRequest = {
        applicationId: order.application.lease.id,
        customerId: order.application.customer?.id,
        monthlyIncome: modifiedFormData.monthlyIncome,
        taxpayerIdentificationNumber: modifiedFormData.taxpayerIdentificationNumber || null, // default to null for empty string
        birthDate: modifiedFormData.birthDate || null, // default to null for empty string
      } as UpdateBasicInfoContinuedInput;

      await updateBasicInfoContinued(updateBasicInfoContinuedRequest);
    } else {
      setWasBasicInfoContinuedUpdated(true);
    }

    if (!!modifiedFormData.taxpayerIdentificationNumber && !!modifiedFormData.birthDate) {
      customerSearchByCellPhone({
        orderId: orderId!,
        taxId: modifiedFormData.taxpayerIdentificationNumber,
        birthDate: modifiedFormData.birthDate,
        cellPhone: cellPhone,
        analytics: {
          campaignMedium: new URLSearchParams(document.location.search.substring(1)).get('cm') || undefined,
          campaignSource: new URLSearchParams(document.location.search.substring(1)).get('cs') || undefined,
        },
      });
    } else {
      setWasEligibilitySkipped(true);
    }

    setDataSaved(true);
  };

  const ssnValidationSchema = Yup.object().shape({
    taxpayerIdentificationNumber: Yup.string()
      .required(' ')
      .min(9, 'Your SSN or ITIN must be 9 digits')
      .matches(/([0-9]){9}/, ' '),
  });

  const monthlyIncomeDoBValidationSchema = Yup.object().shape({
    monthlyIncome: Yup.string().required(' '),
    dob: Yup.string()
      .required(' ')
      .matches(/\d{1,2}\/\d{1,2}\/\d{4}/, ' '),
  });

  let validationSchema = monthlyIncomeDoBValidationSchema;

  if (shouldSeeAllFields) {
    validationSchema = monthlyIncomeDoBValidationSchema.concat(ssnValidationSchema);
  }

  return (
    <FormWrapper>
      <h2 className="formHeader">{shouldSeeAllFields ? `Nice to meet you, ${basicInfo.firstName}!` : "What's your monthly income?"}</h2>
      <h6>{shouldSeeAllFields ? 'We need some more information about you to verify your identity.' : ''}</h6>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...formattedFieldData }}
        validationSchema={validationSchema}
        validate={validateBirthdate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => (
          <Form>
            {shouldSeeAllFields && (
              <Field
                name="dob"
                id="dob"
                label="Date of Birth"
                component={TextField}
                type="custDate"
                autoFocus
                placeholder="MM/DD/YYYY"
                inputMode="numeric"
                cleaveOptions={{
                  date: true,
                  datePattern: ['m', 'd', 'Y'],
                }}
              />
            )}
            {shouldSeeAllFields && (
              <Field
                name="taxpayerIdentificationNumber"
                id="taxpayerIdentificationNumber"
                label="SSN/ITIN"
                component={TextField}
                mask={true}
                type="tel"
                inputMode="numeric"
                useRawValue={true}
                cleaveOptions={{
                  blocks: [3, 2, 4],
                  delimiter: '-',
                  prefix: '',
                  noImmediatePrefix: true,
                }}
                fsExclude
              />
            )}
            <Field
              name="monthlyIncome"
              id="monthlyIncome"
              label="Estimated Monthly Income"
              component={TextField}
              useRawValue={true}
              inputMode="numeric"
              cleaveOptions={{
                prefix: '$',
                numeral: true,
                delimiter: ',',
                // rawValueTrimPrefix: true,
                noImmediatePrefix: true,
                numeralPositiveOnly: true,
              }}
              tooltipContent={IncomeTooltipContent}
            />

            {/* <FormFinePrint>
              By clicking the 'SUBMIT APPLICATION' button, I authorize Koalafi to obtain a credit report, communicate
              with me and share my information with {storeName && storeName}.
            </FormFinePrint> */}
            <FormNavButtonWrapper style={{ marginTop: '0px' }}>
              <Button type="submit" dataLoading={isLoading}>
                {messages.submitButton}
              </Button>
              {/* <Link
                // to="/flow/basic-info"
                onClick={() => {
                  track('Go Back selected', { goingBackTo: '/flow/basic-info' });
                  onNext({ type: CustomerLookupEvents.BACK });
                }}
                linkText={'go back'}
              /> */}
              {showCancelButton && (
                <CLButton
                  onClick={() => {
                    onNext({ type: LongFormEvents.CancelButtonClicked });
                  }}
                  theme="secondary"
                  label="Cancel"
                ></CLButton>
              )}
            </FormNavButtonWrapper>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default BasicInfoContinued;
